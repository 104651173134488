import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));
const CategoryPage = Loadable(lazy(() => import('views/CategoryPage')));
const ListClientsPage = Loadable(lazy(() => import('views/ListClientsPage')));
const ListLocationPage = Loadable(lazy(() => import('views/ListLocationPage')));
const ListProductByUserLocation = Loadable(lazy(() => import('views/ListProductByUserLocation')));
const CartPage = Loadable(lazy(() => import('views/CartPage')));
const CheckoutPage = Loadable(lazy(() => import('views/CheckoutPage')));
const ConfirmCheckoutPage = Loadable(lazy(() => import('views/ConfirmCheckoutPage')));
const OrderPage = Loadable(lazy(() => import('views/OrderPage')));
const SendEmailsPage = Loadable(lazy(() => import('views/SendEmailsPage')));
const SendSingleEmailPage= Loadable(lazy(() => import('views/SendSingleEmailPage')));
// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const Redirect = () => {
    window.location.replace('/');
}

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '*',
            element: <Redirect />
        },
        {
            path: '/',
            element: localStorage.getItem('user_type') == 'ADMIN_USER' ? <OrderPage /> : <ListProductByUserLocation />
        },
        {
            path: 'emails',
            children: [
                {
                    path: 'mass',
                    element: <SendEmailsPage />
                },
                {
                    path: 'single',
                    element: <SendSingleEmailPage />
                },
            ]
        },
        {
            path: 'clients',
            children: [
                {
                    path: '',
                    element: <ListClientsPage />
                }
            ]
        },
        {
            path: 'cart',
            children: [
                {
                    path: '',
                    element: <CartPage />
                },
                {
                    path: 'checkout',
                    element: <CheckoutPage />
                },
                {
                    path: 'checkout/pay',
                    element: <ConfirmCheckoutPage />
                }
            ]
        },
        {
            path: 'orders',
            children: [
                {
                    path: '',
                    element: <OrderPage />
                }
            ]
        },
        {
            path: '/',
            children: [
                {
                    path: '',
                    element: <ListProductByUserLocation />
                }
            ]
        },
        {
            path: 'location',
            children: [
                {
                    path: '',
                    element: <ListLocationPage />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-typography',
                    element: <UtilsTypography />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-color',
                    element: <UtilsColor />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-shadow',
                    element: <UtilsShadow />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'tabler-icons',
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'material-icons',
                    element: <UtilsMaterialIcons />
                }
            ]
        },
        {
            path: 'sample-page',
            element: <SamplePage />
        }
    ],
    
};

export default MainRoutes;
