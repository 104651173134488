// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill,IconTags, IconLocation, IconAt } from '@tabler/icons';
import ListAltIcon from '@mui/icons-material/ListAlt';
import WidgetsIcon from '@mui/icons-material/Widgets';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import InventoryIcon from '@mui/icons-material/Inventory';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';

const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconTags,
    ListAltIcon,
    IconLocation,
    WidgetsIcon,ShoppingCartIcon,InventoryIcon,MailOutlineIcon,IconAt,SendOutlinedIcon

};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: 'utilities',
    title: '',
    type: 'group',
    children: [
        {
            id: 'emails',
            title: 'E-mails',
            type: 'collapse',
            icon: icons.MailOutlineIcon,
            children: [
                {
                    id: 'mass-mails',
                    title: 'Envoyer des e-mails en masse',
                    type: 'item',
                    url: '/emails/mass',
                    icon: icons.SendOutlinedIcon,
                    breadcrumbs: true
                },
                {
                    id: 'single-mails',
                    title: 'Envoyer un seul e-mail',
                    type: 'item',
                    url: '/emails/single',
                    icon: icons.IconAt,
                    breadcrumbs: true
                },
            ]
        },
        {
            id: 'location',
            title: 'Emplacement',
            type: 'item',
            url: '/location',
            icon: icons.IconLocation,
            breadcrumbs: false
        },
        {
            id: 'clients',
            title: 'Client',
            type: 'item',
            url: '/clients',
            icon: icons.ListAltIcon,
            breadcrumbs: false
        },
        // {
        //     id: 'order',
        //     title: 'Commande',
        //     type: 'item',
        //     url: '/orders',
        //     icon: icons.InventoryIcon,
        //     breadcrumbs: false
        // },


    ]
};

export default utilities;
